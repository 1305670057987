@import "minima";

a {
    color: #3c78d8ff;
    &:hover {
        color: #1155ccff !important;
        text-decoration: none;
    }
}

a:visited {
    color: #3c78d8ff;
}

.site-header-custom {
    border-top: 0px;
    border-bottom: 0px;
    margin-top: 5rem;
}

.profile-pic {
    width: 175px;
    margin: 0 0 0 2rem;
    float: right;
}

@media (max-width: 640px) {
    .profile-pic {
        width: 150px;
    }
}

/* Icon Styling */
.menu-btn {
  display: none;
  position: absolute;
  width: 32px;
  height: 24px;
  right: 0;
  top: $base-line-height * 1.125;
}

.menu-btn span,
.menu-btn::before,
.menu-btn::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  right: 0px;
  background: #333;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.menu-btn span {
  top: 50%;
  margin-top: -1px;
}

.menu-btn::before,
.menu-btn::after {
  content: "";
}

.menu-btn::before {
  top: 0px;
}

.menu-btn::after {
  bottom: 0px;
}

/* x */
.menu-btn.x.close span {
  opacity: 0;
}

.menu-btn.x.close::before {
  top: 12px;
  width: 34px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-btn.x.close::after {
  bottom: 10px;
  width: 34px; /* 24 * 1.414 21*/
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (min-width: 641px) and (max-width: 800px) { }

@media (min-width: 801px) and (max-width: 1024px) { }

@media (min-width: 1025px) { }
