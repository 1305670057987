@charset "utf-8";

// Define defaults for each variable.

/* $base-font-family: 'Source Serif 4', 'Georgia', serif; */
$base-font-family: 'Source Sans 3', 'Helvetica', sans-serif;
$base-font-size: 1rem !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;
$base-letter-spacing: 0rem;
/* $small-font-family: 'Public Sans', 'Helvetica', sans-serif; */
$small-font-family: 'Source Sans 3', 'Helvetica', sans-serif;
// $small-font-size:  $base-font-size * 0.85 !default;
// $small-font-weight: 200 !default;
$small-font-size:  $base-font-size * 0.9 !default;
$small-font-weight: 300 !default;

$spacing-unit:     1.5 * $base-font-size !default;

$text-color:       #333 !default;
$background-color: #ffffff !default;
$brand-color:      #333 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@include media-query($on-palm) {

  /* $base-font-size: 1rem !important; */

  .menu-btn {
    right: 0;
    top: -0.3rem;
    display: block !important;
  }

  .site-nav .page-link {
    position: absolute;
    right: 0;
    z-index: 2;
    padding: 0 !important;
    top: 2rem !important;
  }
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
